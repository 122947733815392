import React from "react";
import Map from "./Map";
import blogImage from "../images/img-self-defense.webp";
const EventsDetailSelfDefense = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">
                  Self Defense Awareness Campaign
                </h3>
                <p className="event__text">
                  Purba Dhansara Jote Jana kalyan Samity (NGO) is a local
                  organization that works for health and family social welfare
                  in Darjeeling district rural area. One of their events is the
                  self defense awareness campaign, which aims to empower women
                  and children by teaching them basic self defense techniques
                  and skills.
                </p>
                <p className="event__text">
                  The self defense awareness campaign was held on 22nd December
                  2022 near Ranidanga Darjeeling Public School, along with other
                  events such as voluntary blood donation camp, free eye
                  check-up camp, art competition, blanket distribution camp and
                  social welfare drama. The campaign was conducted by trained
                  instructors who demonstrated and taught various self defense
                  moves, such as kicks, punches, blocks and escapes. The
                  campaign also provided tips and advice on how to avoid and
                  deal with dangerous situations.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  Event Requirements
                </h3>
                <p className="event__text">
                  The self defense awareness campaign was a beneficial and
                  inspiring effort by Purba Dhansara Jote Jana kalyan Samity
                  (NGO) to protect and strengthen women and children. The
                  campaign was appreciated by the participants, who learned
                  valuable skills and gained confidence and courage. The
                  campaign also raised awareness about the importance of self
                  defense and women's safety among the people. The NGO plans to
                  organize more such campaigns in the future to reach out to
                  more people in need.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailSelfDefense;
