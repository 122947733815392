import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import EventsDetailSelfDefense from "../components/EventsDetailSelfDefense";

const SelfDefenseCampaign = () => {
  return (
    <Layout pageTitle="PDJJKS | Self Defense Events Detail">
      <NavOne />
      <PageHeader title="Self Defense" />
      <EventsDetailSelfDefense />
      <Footer />
    </Layout>
  );
};

export default SelfDefenseCampaign;
